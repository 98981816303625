import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'
import { colors, media } from '../tokens'
import useAllMdxNodes from '../hooks/use-allMdx-config'
import {Row} from 'react-bootstrap'

const Wrapper = styled.div`
    
    width: 0px;
    @media ${media.minMedium} {
        width: 160px;
    }    
    position: sticky;
    top: 120px;
    z-index: 1;
    background-color:  ${colors.primary};
    overflow-x: hidden;
    margin-top: 45px;
    border-radius: 15px;

    a {
        padding: 6px 8px 6px 16px;
        text-decoration: none;
        font-size: 18px;
        color: #818181;
        display: block;
    }

    a:hover{
        color: ${colors.dypPurpleLight}
    } 
`

const SideBar = (props) => {

    return (
        <Wrapper>
            {props.children}
        </Wrapper>
    )
    
}

export default SideBar