import React from "react"
import LayoutAsset from "../../components/LayoutAsset"
import AnimJamVideo from "../../../content/images/Assets/BlenderTools/RigOnTheFly/AnimationJamWinner/Video.mp4"
import { Link } from "gatsby"

export const AssetInfo = {
   title:"ANIMATION JAM WINNER 2020",
   description: "An animation made by Eduardo Rubio using Rig On The Fly which won first place in an animation jam.",
   link:"/Assets/AnimationJamWinner" ,
   boxImage:"Assets/BlenderTools/RigOnTheFly/AnimationJamWinner/_Box.png",
   bannerImage:"Assets/BlenderTools/RigOnTheFly/AnimationJamWinner/_Banner.png",
   primaryButton: { 
      label: "DOWNLOAD",
      href: "/DownloadFiles/EduardoRubioAnimJam2020.zip"
   },
   images: [
      {
      original: 'Assets/BlenderTools/RigOnTheFly/AnimationJamWinner/_Box.png',
      thumbnail: 'Assets/BlenderTools/RigOnTheFly/AnimationJamWinner/_Box.png',
      description: "",
      originalAlt : 'ANIMATION JAM WINNER 2020',
      thumbnailAlt: 'ANIMATION JAM WINNER 2020',
      },
      {
      original: 'Assets/BlenderTools/RigOnTheFly/AnimationJamWinner/animJam2020ER_InBlender.PNG',
      thumbnail: 'Assets/BlenderTools/RigOnTheFly/AnimationJamWinner/animJam2020ER_InBlender.PNG',
      description: "",
      originalAlt : 'ANIMATION JAM WINNER 2020',
      thumbnailAlt: 'ANIMATION JAM WINNER 2020',
      },
    ],
   documentationLink:"https://gitlab.com/dypsloom/rigonthefly/-/blob/master/README.md",
   //forumLink:"/Forum/",
   //videosLink:"/#"
}

export default () => (
  <LayoutAsset  assetInfo={AssetInfo}>
     <h1>Animation Jam Winner 2020</h1>
     <p>
      This animation made by Eduardo Rubio won first place in the professional category for the Animation Jam 3 of March 2020, organized by <a href="https://www.teatimeanimation.com/">Tea Time Animation</a> 
      <br/>The goal was to animate an idle, walk, attack, death and a bonus, under 48h using any rig while respecting this year's theme "Villains".
      <br/>He used Richard Lico's Baquir rig combined with this cute Rat by Moueva. The animation was made using an early version of the addon Rig On The Fly.
      </p>

      <p>
      The zip file contains the original blend file with the full content: models, animations, textures, etc... and another file with just the rigged rat. You can download it for free!
      </p>
      
      
      <video width="740" height="480" controls>
         <source src={AnimJamVideo} type="video/mp4"/>
         Your browser does not support the video tag.
      </video>
   
  </LayoutAsset>
)