import { useStaticQuery, graphql } from 'gatsby'

const useAllMdxNodes = () => {
  const result = useStaticQuery(graphql`
    {
      allMdx (sort: {fields: fields___order}) {
        nodes {
            fields {
                slug
                order
            }
            frontmatter {
              title
            }
        }
      }
    }
  `)
  return result.allMdx.nodes
}

export default useAllMdxNodes
