import React from "react"
import LayoutAsset from "../../components/LayoutAsset"
import { Link } from "gatsby"

export const AssetInfo = {
   title:"RIG ON THE FLY",
   description: "A blender add-on that speeds up animation by creating dynamic rigs.",
   link:"/Assets/RigOnTheFly" ,
   boxImage:"Assets/BlenderTools/RigOnTheFly/_Box.png",
   bannerImage:"Assets/BlenderTools/RigOnTheFly/_Banner.png",
   primaryButton: { 
      label: "FREE",
      href: "https://gitlab.com/dypsloom/rigonthefly"
   },
   images: [
      {
      original: 'https://gitlab.com/dypsloom/rigonthefly/-/raw/master/images/walkShowcase.gif',
      thumbnail: 'https://gitlab.com/dypsloom/rigonthefly/-/raw/master/images/walkShowcase.gif',
      description: 'Rig on the Fly is a Blender 2.80 dynamic rigging tool used to simplify and speed up animation workflow.',
      originalAlt : 'Rig On The Fly for Blender 2.80',
      thumbnailAlt: 'Rig On The Fly for Blender 2.80',
      },
      {
      original: 'https://gitlab.com/dypsloom/rigonthefly/-/raw/master/images/RigOnSkeleton.gif',
      thumbnail: 'https://gitlab.com/dypsloom/rigonthefly/-/raw/master/images/RigOnSkeleton.gif',
      description: 'RIG SKELETON: Adds basic FK controllers to a selected armature. Keeping any animation keys it had.',
      originalAlt : 'Rig On The Fly: RIG SKELETON',
      thumbnailAlt: 'Rig On The Fly: RIG SKELETON',
      },
      {
      original: 'https://gitlab.com/dypsloom/rigonthefly/-/raw/master/images/BakeOnSkeleton.gif',
      thumbnail: 'https://gitlab.com/dypsloom/rigonthefly/-/raw/master/images/BakeOnSkeleton.gif',
      description: "BAKE RIG: Applies the rig's animation on to the base armature and removes all controllers created by RigOnTheFly. Usually used once you are ready to export your animation but can also be used to reset the rig's controls.",
      originalAlt : 'Rig On The Fly: BAKE RIG',
      thumbnailAlt: 'Rig On The Fly: BAKE RIG',
      },
      {
      original: 'https://gitlab.com/dypsloom/rigonthefly/-/raw/master/images/OrientRig.gif',
      thumbnail: 'https://gitlab.com/dypsloom/rigonthefly/-/raw/master/images/OrientRig.gif',
      description: "Orient RIG/BAKE: In case your armature was imported from another 3d software use Orient Rig to make it compatible with RigOnTheFLy. If your imported armature does not have animations yet, make sure to key all bones once before pressing Orient Rig to prevent unwanted rotations. Bake Orient is the equivalent to Bake Rig but for armatures that used Orient Rig first.",
      originalAlt : 'Rig On The Fly: Orient RIG/BAKE',
      thumbnailAlt: 'Rig On The Fly: Orient RIG/BAKE',
      },
      {
      original: 'https://gitlab.com/dypsloom/rigonthefly/-/raw/master/images/ControllerSize2.gif',
      thumbnail: 'https://gitlab.com/dypsloom/rigonthefly/-/raw/master/images/ControllerSize2.gif',
      description: "CONTROLLER SIZE: Increase or decrease selected controllers' size to your liking. Does not affect animation in any way.",
      originalAlt : 'Rig On The Fly: CONTROLLER SIZE',
      thumbnailAlt: 'Rig On The Fly: CONTROLLER SIZE',
      },
      {
      original: 'https://gitlab.com/dypsloom/rigonthefly/-/raw/master/images/IKFK3.gif',
      thumbnail: 'https://gitlab.com/dypsloom/rigonthefly/-/raw/master/images/IKFK3.gif',
      description: "IK FK SWITCH: Switches selected controllers to work in Inverse Kinematic/IK with pole targets. (Two limbs IK only). To switch back to FK, simply select the desired IK handles and press the FK button. Press Shift IK Pole Angle if the elbow or knee is facing in the wrong direction. Goes by 90° increments.",
      originalAlt : 'Rig On The Fly: IK FK SWITCH',
      thumbnailAlt: 'Rig On The Fly: IK FK SWITCH',
      },
      {
      original: 'https://gitlab.com/dypsloom/rigonthefly/-/raw/master/images/RotationDistribution.gif',
      thumbnail: 'https://gitlab.com/dypsloom/rigonthefly/-/raw/master/images/RotationDistribution.gif',
      description: "DISTRIBUTE: Distributes rotation between the first and last selected controllers without modifying the existing animation. *Disables translation of top controller. After selecting the top controller, press Apply to return to how it was.",
      originalAlt : 'Rig On The Fly: DISTRIBUTE',
      thumbnailAlt: 'Rig On The Fly: DISTRIBUTE',
      },
      {
      original: 'https://gitlab.com/dypsloom/rigonthefly/-/raw/master/images/InheritRotationScaleSwitch2.gif',
      thumbnail: 'https://gitlab.com/dypsloom/rigonthefly/-/raw/master/images/InheritRotationScaleSwitch2.gif',
      description: 'INHERITING ROTATION/SCALE: Pressing On enables selected controllers to inherit rotation/scale transforms from their parents. Pressing Off disables it.',
      originalAlt : 'Rig On The Fly: INHERITING ROTATION/SCALE',
      thumbnailAlt: 'Rig On The Fly: INHERITING ROTATION/SCALE',
      },
    ],
   documentationLink:"https://gitlab.com/dypsloom/rigonthefly/-/blob/master/README.md",
   //forumLink:"/Forum/",
   //videosLink:"/#"
}

export default () => (
  <LayoutAsset  assetInfo={AssetInfo}>
     <h1>Rig On The Fly</h1>
     <p>
      Rig on the Fly is a Blender 2.80 dynamic rigging tool used to simplify and speed up animation workflow.
      Inspired by Richard Lico's GDC 2018 talk: Animating Quill: Creating an Emotional Experience and his Animation Sherpa Space Switching course.
      The tool pairs very well with another one called AnimAide.
      </p>
      <p>
      The main goal of Rig on the Fly is to facilitates animators job by automating repetitive tasks such as rigging. 
      It does so by modularizing the rig allowing changes to how it functions on the fly without losing motion. 
      Letting animators focus on performance rather than find ways to circumvent rig limitations.
      </p>
  </LayoutAsset>
)