import React from "react"
import {LayoutPage} from "../components/Layouts"
import SideBar from "../components/SideBar"
import styled from "styled-components"
import { colors, media } from '../tokens'
import { Container, Button, Col, Row, Dropdown } from "react-bootstrap"
import { Link } from "gatsby"
import '../utils/FontAwesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AssetBox from "../components/AssetBox"
import { AssetInfo as RhythmTimelineAssetInfo } from "./Assets/RhythmTimeline"
import { AssetInfo as RigOnTheFlyAssetInfo } from "./Assets/RigOnTheFly"
import { AssetInfo as CuteBoarAssetInfo } from "./Assets/UnityArt/CuteBoar"
import { AssetInfo as DypThePenguinInfo } from "./Assets/DypThePenguin"
import { AssetInfo as AnimationJamWinner } from "./Assets/AnimationJamWinner"

const Section = styled.section`
  margin: -80px 0px;
  padding-top: 120px
`

const Body = styled(Col)`
  padding-bottom: 30px;
`

export default () => (
  <LayoutPage 
  location="/Assets/" 
  title="ASSETS"
  heroImg=""
  description="Our asset can speed up your development!"
  >

    <Row>
      <Col md={2}>
        <SideBar>
          <a href="#Latest">Latest Assets</a>
          <a href="#UnityTools">Unity Systems & Tools</a>
          <a href="#UnityArt">Unity Models & Animations</a>
          <a href="#BlenderAddons">Blender Add-ons</a>
          <a href="#Free">Free</a>
        </SideBar>
      </Col>

      <Body md={10}>
        <Section>
          <p>
            We offer a range of high quality assets which will help you get the most out of your game development journey.
            Our diverse team of developers have complementary skills which allow them to concentrate on what they are good at and make beatiful and functional assets.
            Most of our assets are for Unity and sold on the Unity asset store. But we also make packages and assets for other platforms such as Blender.
          </p>
          <p>
            Make sure to check out our assets below!
          </p>
        </Section>

        <Section id="Latest">
          <h2>Our latest updated assets</h2>
          <Row>
            <AssetBox assetInfo={RhythmTimelineAssetInfo} />
            <AssetBox assetInfo={RigOnTheFlyAssetInfo} />
            <AssetBox assetInfo={DypThePenguinInfo} />
          </Row>
        </Section>
          
        <Section id="UnityTools">
          <h2>Unity Systems & Tools</h2>
          <Row>
            <AssetBox assetInfo={RhythmTimelineAssetInfo} />
            <AssetBox assetInfo={DypThePenguinInfo} />
          </Row>
        </Section>

        <Section id="UnityArt">
          <h2>Unity Models and Animations</h2>
          <Row>
            <AssetBox assetInfo={CuteBoarAssetInfo} />
            <AssetBox assetInfo={DypThePenguinInfo} />
          </Row>
        </Section>

        <Section id="BlenderAddons">
          <h2>Blender Add-ons</h2>
          <Row>
            <AssetBox assetInfo={RigOnTheFlyAssetInfo} />
          </Row>
        </Section>

        <Section id="Free">
          <h2>Free Assets!</h2>
          <Row>
            <AssetBox assetInfo={RigOnTheFlyAssetInfo} />
            <AssetBox assetInfo={DypThePenguinInfo} />
            <AssetBox assetInfo={AnimationJamWinner} />
          </Row>
        </Section>
      </Body>
    </Row>
    
      
  </LayoutPage>
)